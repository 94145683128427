<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field v-model="timestring" :label="timelabeltext" prepend-icon="event" readonly v-bind="attrs" v-on="on">
      </v-text-field>
    </template>
    <v-time-picker v-model="timestring" scrollable @change="timeassinged"></v-time-picker>
  </v-menu>
</template>
<!--<TimePicker :menu="menu" :timevalue="timevalue",:timelabeltext="timelabeltext" :rules="rules" @timeassinged=" timeassinged=$event"/>-->
<script>
export default {
  name: "TimePicker",

  props: {
    menu: {
      type: Boolean,
    },
    timevalue: {
      type: String,
    },
    timelabeltext: {
      type: String,
    },
    rules: {
      type: Array,
    },
  },
  data() {
    return {
      timestring: "",
    }
  },

  created() {
    this.timestring = this.timevalue
    console.log("Created-Timepicker", this.menu, this.timestring, this.timelabeltext)
  },
  methods: {
    timeassinged() {
      this.$emit("timeassinged", this.timestring)
    },
  },
}
</script>
