<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :rules="rules"
        v-model="computedDateFormatted"
        :label="datelabeltext"
        prepend-icon="event"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker v-model="datevalue" @input="dateassinged"></v-date-picker>
  </v-menu>
</template>
<!--  <DatePicker  :menu="menupd" :datevalue="editedItem.purchaseDate"   :datelabeltext="'Purchase Date'"
   @dateassinged="dateassinged"   :rules="inputrequired" />-->
<script>
import { convertAll } from "@/mixins/convertall.js"

export default {
  name: "DatePicker",
  mixins: [convertAll],

  props: {
    menu: {
      type: Boolean,
    },
    datevalue: {
      type: String,
    },
    datelabeltext: {
      type: String,
    },
    rules: {
      type: Array,
    },
  },
  data() {
    return {
      datestring: "",
    }
  },

  computed: {
    computedDateFormatted() {
      return this.convertDate(this.datevalue)
    },
  },

  created() {
    console.log("Created-DatePicker", this.menu, this.datevalue, this.datelabeltext)
  },
  methods: {
    dateassinged() {
      // this.menudp=false
      console.log("Selected Date value is ", this.datevalue)
      this.$emit("dateassinged", this.datevalue)
    },
  },
}
</script>
