<!--  The profile code will do the following 
1. Fetch the job details (like job code, job description, number of openings, status(open/close), and process flow-->
->
<template>
  <v-container>
    <!-- for alert message display-->
    <Alertmsg v-if="alertmain" :alertmsg="alertmsg" @setflasealert="alertmain = false"> </Alertmsg>
    <!--  for user notification once created and updated profile -->
    <Snackbar :snackbar="snackbar" :snackbartext="snackbartext" @setflasesnackbar="snackbar = false"> </Snackbar>
    <div>
      <v-row class="flex" centre>
        <!-- tabs for main profile page three tabs 
      1 Dashboard 2. logged in user assinged task 3. list of status (user can change the status and view the list-->
        <v-toolbar color="#16283F" dark flat>
          <v-tabs align="left" fixed-tabs>

            
            <v-tab v-if="allmenus">
              <v-menu v-if="alltabstatus.length" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text class="align-self-center mr-4" v-bind="attrs" v-on="on">
                    Status
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>

                <v-list class="grey lighten-3">
                  <v-list-item v-for="item in alltabstatus" :key="item" @click="setstatus(item)">
                    {{ item }}
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-tab>
            <v-tab small tile text @click="setstatus(`tasklist`)">My Task </v-tab>
            <v-tab v-if="allmenus" left small tile text @click="setstatus(`dashboard`)">dashboard </v-tab>
            <v-tab v-if="allmenus" left small tile text @click="setstatus(`Export`)">Reports </v-tab>
          </v-tabs>
        </v-toolbar>
      </v-row>
    </div>
    <!-- for dash board  it will list the open jobs with various status in chips-->
    <div v-if="currentstatus == 'dashboard'">
      <v-row>
        <div v-for="dashboard in dashboardarray" :key="dashboard.JobId">
          <v-col cols="12" xs="12" sm="12" md="12" lg="12">
            <v-card min-width="200" :color="randomColor()" class="white--text">
              <v-card-title primary-title>
                <div class="headline">{{ dashboard.jobId }}-{{ dashboard.title }}</div>
              </v-card-title>
              <v-divider class="bold"></v-divider>
              <v-row dense>
                <v-col cols="12" xs="9" sm="9" md="9" lg="9">
                  <div class="ml-2">Total Opening</div>
                </v-col>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3">
                  {{ dashboard.totalOpen }}
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" xs="9" sm="9" md="9" lg="9">
                  <div class="ml-2">Current Opening</div>
                </v-col>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3">
                  {{ dashboard.currentOpen }}
                </v-col>
              </v-row>
              <v-divider class="bold"></v-divider>
              <!-- <div v-for="(statuscount) in dashboard.statuscounts" :key="statuscount.status">-->
              <v-row dense v-for="statuscount in dashboard.statuscounts" :key="statuscount.status">
                <v-col cols="12" xs="9" sm="9" md="9" lg="9">
                  <div class="ml-2">{{ statuscount.dbstatus }}</div>
                </v-col>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3">
                  {{ statuscount.count }}
                </v-col>
              </v-row>
              <!--</div>   -->
            </v-card>
          </v-col>
        </div>
      </v-row>
    </div>
    <!-- end of dashboard view -->

    <v-data-table
     v-else
      :headers="headers"
      :items="getdatatablearray"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="uid"
      class="elevation-1"
      :show-expand="expandflag"
      :items-per-page="itemsPerPage"
      :loading="notloaded"
      loading-text="Loading..."
      :search="search"
      :footer-props="{ prevIcon: 'mdi-arrow-left', nextIcon: 'mdi-arrow-right' }"
      @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
    >
      <template v-slot:[`item.changedAt`]="{ item }">
        {{ convertDate(item.changedAt) }}
      </template>

      <template v-slot:[`item.candidateNumber`]="{ item }">
        {{ convertmobile(item.candidateNumber) }}
      </template>

      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Recruitment List </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
          <v-dialog v-model="dialog" max-width="1000px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2 mx-4" v-bind="attrs" v-on="on" @click="getaddvalues"
                >ADD PROFILE</v-btn>
            </template>
            <v-form ref="form" v-model="valid" :lazy-validation="lazy">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                        <!-- there will be three tabls for each profile detils create and update 
                     1.Candidate Details(to show the profile details like name , mobile numebr , CTC....) 
                     2. Comments and scores 
                     3.Test reports.-->
                        <v-toolbar color="#16283F" dark flat>
                          <v-tabs fixed-tabs dark>
                            <v-tab small tile text color="secondary" @click="taboption='Candidate Details'"
                              >Candidate Details
                            </v-tab>
                            <v-tab small tile text color="secondary" @click="taboption='Interview Comments'"
                              >Interview Comments
                            </v-tab>
                            <v-tab
                              small
                              v-if="editedItem.testreport.length > 0"
                              tile
                              text
                              color="secondary"
                              @click="taboption='Test Report'"
                              >Test Report
                            </v-tab>
                          </v-tabs>
                        </v-toolbar>
                      </v-col>
                      </v-row>

                      <v-row v-if="taboption=='Candidate Details'">
                      
                      <v-col  cols="12" xs="2" sm="2" md="1" lg="1">
                        <v-combobox
                          v-if="jobnotselected"
                          v-model="editedItem.jobId"
                          label="Job ID"
                          :rules="inputrequired"
                          :items="jobids"
                          @input="selectedJobs"
                        >
                        </v-combobox>
                        <div v-else>
                          <div>Job ID</div>
                          {{ editedItem.jobId }}
                        </div>
                      </v-col>
                      <v-col  cols="12" xs="4" sm="4" md="3" lg="3">
                        <div>
                          <div>Title</div>
                          {{ editedItem.title }}
                        </div>
                      </v-col>
                      <v-col  cols="12" xs="6" sm="6" md="3" lg="3">
                        <v-text-field
                          v-model="editedItem.candidateName"
                          label="Name"
                          :rules="inputrequired"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col  cols="12" xs="6" sm="6" md="3" lg="3">
                        <v-text-field
                          v-model="editedItem.email"
                          label="E-mail"
                          :rules="emailRules"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" xs="4" sm="4" md="2" lg="2">
                        <v-text-field
                          v-model="editedItem.candidateNumber"
                          type="Number"
                          label="Mobile Number"
                          @keyup.enter="checkmobilenumber"
                          @keydown.tab="checkmobilenumber"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col  cols="12" xs="6" sm="6" md="4" lg="4">
                        <template>
                          <div>Skills</div>
                          <v-combobox v-model="editedItem.skills" chips clearable multiple solo>
                            <template v-slot:selection="{ attrs, item, select, selectedskill }">
                              <v-chip
                                v-bind="attrs"
                                :input-value="selectedskill"
                                close
                                @click="select"
                                @click:close="remove(item)"
                              >
                                <strong>{{ item }}</strong
                                >&nbsp;
                              </v-chip>
                            </template>
                          </v-combobox>
                        </template>
                      </v-col>

                      <v-col  cols="12" xs="4" sm="4" md="2" lg="2">
                        <v-select v-model="editedItem.source" :items="sources" :rules="inputrequired" label="Source">
                        </v-select>
                      </v-col>

                        <v-col cols="12" xs="4" sm="4" md="3" lg="3">
                        <v-text-field
                          v-model="editedItem.sourcename"
                          label="Source ID"
                        ></v-text-field>
                      </v-col>
                      <v-col  cols="12" xs="4" sm="4" md="1" lg="1">
                        <div>
                          <div>Test</div>
                          {{ editedItem.test }}
                        </div>
                      </v-col>
                      

                      <v-col  cols="12" xs="4" sm="4" md="2" lg="2">
                        <v-text-field
                          v-model="editedItem.noticePeriod"
                          type="Number"
                          label="Notice Period(in days)"
                          :required="getnoticePeriodrequiredOption"
                        ></v-text-field>
                      </v-col>

                     

                      <v-col cols="12" xs="4" sm="4" md="3" lg="3">
                        <v-text-field
                          v-model="editedItem.currentCTC"
                          type="Number"
                          label="Current CTC(in lakhs)"
                        ></v-text-field>
                      </v-col>

                      <v-col  cols="12" xs="4" sm="4" md="3" lg="3">
                        <v-text-field
                          v-model="editedItem.expectedCTC"
                          type="Number"
                          label="Expected CTC(in lakhs)"
                        ></v-text-field>
                      </v-col>

                      <v-col  cols="12" xs="4" sm="4" md="3" lg="3">
                        <v-text-field
                          v-model="editedItem.totalExperience"
                          type="Number"
                          label="Total Experience(years)"
                        ></v-text-field>
                      </v-col>

                      <v-col  cols="12" xs="4" sm="4" md="3" lg="3">
                        <v-text-field
                          v-model="editedItem.relaventExperience"
                          type="Number"
                          label="Relavent Experience(years)"
                        ></v-text-field>
                      </v-col>

                      <v-col  cols="12" xs="4" sm="4" md="3" lg="3">
                        <v-select v-model="editedItem.status"  :rules="inputrequired" :items="allStatus" label="Status" @change="checkstatus">
                        </v-select>
                      </v-col>

                      <v-col cols="12" xs="6" sm="6" md="4" lg="4">
                        <v-combobox v-model="editedItem.assingedTo" :items="allemails" label="Assinged To">
                        </v-combobox>
                      </v-col>

                      <v-col  cols="12" xs="4" sm="4" md="2" lg="2">
                        <DatePicker
                          :menu="menuID"
                          :datevalue="editedItem.eventdate"
                          :datelabeltext="'Event Date'"
                          @dateassinged="editedItem.eventdate = $event"
                        />
                      </v-col>

                      <v-col  cols="12" xs="6" sm="6" md="2" lg="2">
                        <TimePicker
                          :menu="menuIT"
                          :timevalue="editedItem.eventtime"
                          :timelabeltext="'Time'"
                          @timeassinged="editedItem.eventtime = $event"
                        />
                      </v-col>
                        <v-col  cols="12" xs="4" sm="4" md="2" lg="2">
                        <v-select v-model="editedItem.pStatus" :items="pStatusValues"  :rules="inputrequired" label="Profile Status">
                        </v-select>
                      </v-col>

                      <v-col
                        v-if="editedItem.test == 'Needed'"
                        cols="12"
                        xs="4"
                        sm="4"
                        md="3"
                        lg="3"
                      >
                        <Slider
                          :lableString="'Test Score'"
                          :datavalue="editedItem.scores.test"
                          :step="1"
                          :minvalue="0"
                          :maxvalue="100"
                          @assignslidervalues="editedItem.scores.test = $event"
                        />
                      </v-col>
                      <template v-if="editedItem.status =='Offer Printed'">
                      

                      <v-col  cols="12" xs="4" sm="4" md="3" lg="3">
                        <v-text-field
                          v-model="editedItem.offeredCTC"
                          :rules="decimalinput"
                          label="Offered CTC in Rs"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col  cols="12" xs="4" sm="4" md="3" lg="3">
                        <v-text-field
                          v-model="editedItem.VariablePay"
                          :rules="decimalinput"
                          label="VariabilePay in Rs"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col  cols="12" xs="4" sm="4" md="2" lg="2">
                        <v-text-field
                          v-model="editedItem.professionalTax"
                          :rules="decimalinput"
                          label="Professional Tax in Rs"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col  cols="12" xs="4" sm="4" md="2" lg="2">
                        <v-text-field
                          v-model="editedItem.bpratio"
                          :rules="decimalinput"
                          label="BP Ratio in %"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col  cols="12" xs="4" sm="4" md="2" lg="2">
                        <DatePicker
                          :menu="menuDOS"
                          :datevalue="editedItem.sigingdate"
                          :datelabeltext="'Signing Date'"
                          :rules="inputrequired"
                          @dateassinged="editedItem.sigingdate = $event"
                        />
                      </v-col>
                     
                      </template> <!-- end of status is selected -->
                           <!-- will call the upload moudle to attach the file and display the files. -->
                      <v-col  cols="12" xs="6" sm="6" md="6" lg="6">
                        <Upload
                          ref="Resume"
                          :attachments="editedItem.resume"
                          :storagepath="storagePath_resume"
                          :uploadname="`Resume`"
                          :disabled="disabled"
                          @addAttachment="addAttachment"
                          @removeAttachment="removeAttachment"
                        />
                      </v-col>
                      <v-col
                        v-if="editedItem.test == 'Needed'"
                        cols="12"
                        xs="6"
                        sm="6"
                        md="6"
                        lg="6"
                      >
                        <Upload
                          ref="testreports"
                          :attachments="editedItem.testreport"
                          :storagepath="storagePath_testreport"
                          :uploadname="`TestReports`"
                          :disabled="disabled"
                          @addAttachment="addAttachment"
                          @removeAttachment="removeAttachment"
                        />
                      </v-col>
                       <v-col cols="12" xs="6" sm="6" md="6" lg="6">
                        <Upload
                          ref="Offers"
                          :attachments="editedItem.offer"
                          :storagepath="storagePath_offer"
                          :uploadname="`Offers`"
                          :disabled="disabled"
                          @addAttachment="addAttachment"
                          @removeAttachment="removeAttachment"
                        />
                      </v-col>
                       </v-row>

                     <!-- </div> end of candidate details -->

                      <v-row v-if="taboption == 'Interview Comments'">

                      <v-col  cols="12" xs="6" sm="6" md="6" lg="6">
                        <tipTapVuetify
                          :tiptabheader="'HR Comments'"
                          :editflag="geteditflag"
                          :tiptablable="'HR Comments'"
                          :tiptabstring="editedItem.hrcomments"
                          @assigntiptabvalues="editedItem.hrcomments = $event"
                        />
                      </v-col>

                      <v-col  cols="12" xs="6" sm="6" md="6" lg="6">
                        <tipTapVuetify
                          :tiptabheader="'Manager Comments'"
                          :tiptablable="'Manager Comments'"
                          :tiptabstring="editedItem.manangercomments"
                          @assigntiptabvalues="editedItem.manangercomments = $event"
                        />
                      </v-col>

                      <v-col
                        :class="checkupdateflagclient1"
                        cols="12"
                        xs="6"
                        sm="6"
                        md="6"
                        lg="6"
                      >
                        <tipTapVuetify
                          :tiptabheader="'Client1 Comments'"
                          :tiptablable="'Client1 Comments'"
                          :tiptabstring="editedItem.clientcomments"
                          @assigntiptabvalues="editedItem.clientcomments = $event"
                        />
                      </v-col>

                      
                      <v-col
                        :class="checkupdateflagclient2"
                        cols="12"
                        xs="6"
                        sm="6"
                        md="6"
                        lg="6"
                      >
                        <tipTapVuetify
                          :tiptabheader="'Client Comments-2'"
                          :tiptablable="'Client Comments-2'"
                          :tiptabstring="editedItem.clientcomments2"
                          @assigntiptabvalues="editedItem.clientcomments3 = $event"
                        />
                      </v-col>  
                      <v-col
                        :class="checkupdateflagclient3"
                        cols="12"
                        xs="6"
                        sm="6"
                        md="6"
                        lg="6"
                      >
                        <tipTapVuetify
                          :tiptabheader="'Client Comments-3'"
                          :tiptablable="'Client Comments-3'"
                          :tiptabstring="editedItem.clientcomments3"
                          @assigntiptabvalues="editedItem.clientcomments3 = $event"
                        />
                      </v-col>
                      <v-col  cols="12" xs="4" sm="4" md="3" lg="3">
                        <!-- <Slider
                          :lableString="'Manager Score'"
                          :datavalue="editedItem.scores.manager"
                          :step="1"
                          :minvalue="0"
                          :maxvalue="10"
                          @assignslidervalues="editedItem.scores.manager = $event"
                        /> -->
                      </v-col>
                      <v-col  cols="12" xs="4" sm="4" md="3" lg="3">
                        <Slider
                          :lableString="'Manager Score'"
                          :datavalue="editedItem.scores.manager"
                          :step="1"
                          :minvalue="0"
                          :maxvalue="10"
                          @assignslidervalues="editedItem.scores.manager = $event"
                        />
                      </v-col>

                      <v-col
                        :class="checkupdateflagclient1"
                        cols="12"
                        xs="4"
                        sm="4"
                        md="3"
                        lg="3"
                      >
                        <Slider
                          :lableString="'Client1 Score'"
                          :datavalue="editedItem.scores.client1"
                          :step="1"
                          :minvalue="0"
                          :maxvalue="10"
                          @assignslidervalues="editedItem.scores.client1 = $event"
                        />
                      </v-col>

                      <v-col
                        :class="checkupdateflagclient2"
                        cols="12"
                        xs="4"
                        sm="4"
                        md="3"
                        lg="3"
                      >
                        <Slider
                          :lableString="'Client2 Score'"
                          :datavalue="editedItem.scores.client2"
                          :step="1"
                          :minvalue="0"
                          :maxvalue="10"
                          @assignslidervalues="editedItem.scores.client2 = $event"
                        />
                      </v-col>

                      <v-col
                        :class="checkupdateflagclient3"
                        cols="12"
                        xs="4"
                        sm="4"
                        md="3"
                        lg="3"
                      >
                        <Slider
                          :lableString="'Client3 Score'"
                          :datavalue="editedItem.scores.client3"
                          :step="1"
                          :minvalue="0"
                          :maxvalue="10"
                          @assignslidervalues="editedItem.scores.client3 = $event"
                        />
                      </v-col>

                      <v-col  cols="12" xs="4" sm="4" md="1" lg="1">
                        <div>
                          <h4>Total</h4>
                          {{ editedItem.scores.total }}
                        </div>
                      </v-col>
                      <v-col  cols="12" xs="4" sm="4" md="2" lg="2">
                        <div>
                          <h4>Average</h4>
                          {{ editedItem.scores.average }}
                        </div>
                      </v-col>
                      </v-row> <!-- end of interview comments -->
                     
                      <v-row v-if="taboption == 'Test Report'" class="form-group d-flex">
                        <div class="img-wrapp">
                          <div v-for="(image, index) in editedItem.testreport" :key="index">
                            <div class="img-wrapp">
                              <div>{{ image.filename }}</div>
                              <embed :src="image.fileurl" width="800px" height="700px" />
                            </div>
                          </div>
                        </div>
                      </v-row>

                     
                   <!-- </v-row>-->
                    <Alertmsg v-if="alertflag" :alertmsg="alertmsg" @setflasealert="alertflag = false"> </Alertmsg>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                  <v-btn color="blue darken-1" v-if="!spinner" :disabled="!valid" text @click="saveitem">Save</v-btn>
                  <Spinner v-if="spinner" />
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-row>
            <v-col cols="12" xs="4" sm="4" md="4" lg="4">
              <h4>Email</h4>
              {{ item.email }}
            </v-col>
            <v-col cols="12" xs="4" sm="4" md="2" lg="2">
              <h4>source</h4>
              {{ item.source }}
            </v-col>
            <v-col cols="12" xs="4" sm="4" md="2" lg="2">
              <h4>Test</h4>
              {{ item.test }}
            </v-col>

            <v-col cols="12" xs="4" sm="4" md="2" lg="2">
              <h4>Manager Interview Date</h4>
              {{ convertDate(item.DateTimes.manager.date) }}
            </v-col>
            <v-col cols="12" xs="4" sm="4" md="2" lg="2">
              <h4>Manager Interview Time</h4>
              {{ item.DateTimes.manager.time }}
            </v-col>
            <v-col cols="12" xs="4" sm="4" md="2" lg="2">
              <h4>Client1 Interview Date</h4>
              {{ convertDate(item.DateTimes.client1.date) }}
            </v-col>
            <v-col cols="12" xs="4" sm="4" md="2" lg="2">
              <h4>Client1 Interview Time</h4>
              {{ item.DateTimes.client1.time }}
            </v-col>
            <v-col cols="12" xs="4" sm="4" md="2" lg="2">
              <h4>Client2 Interview Date</h4>
              {{ convertDate(item.DateTimes.client2.date) }}
            </v-col>
            <v-col cols="12" xs="4" sm="4" md="2" lg="2">
              <h4>Client2 Interview Time</h4>
              {{ item.DateTimes.client2.time }}
            </v-col>
             <v-col cols="12" xs="4" sm="4" md="2" lg="2">
              <h4>Client3 Interview Date</h4>
              {{ convertDate(item.DateTimes.client3.date) }}
            </v-col>
            <v-col cols="12" xs="4" sm="4" md="2" lg="2">
              <h4>Client3 Interview Time</h4>
              {{ item.DateTimes.client3.time }}
            </v-col> 
            <v-col cols="12" xs="4" sm="4" md="2" lg="2">
              <h4>Notice Period</h4>
              {{ item.noticePeriod }}
            </v-col>

            <v-col cols="12" xs="4" sm="4" md="2" lg="2">
              <h4>Created Date</h4>
              {{ convertDate(item.createdAt) }}
            </v-col>

            <v-col v-if="item.scores && item.test == 'Needed'" cols="12" xs="4" sm="4" md="2" lg="2">
              <h4>Test Score</h4>
              {{ item.scores.test }}
            </v-col>

            <v-col v-if="item.scores" cols="12" xs="4" sm="4" md="2" lg="2">
              <h4>Manager Score</h4>
              {{ item.scores.manager }}
            </v-col>

            <v-col v-if="item.scores" cols="12" xs="4" sm="4" md="2" lg="2">
              <h4>Client1 Score</h4>
              {{ item.scores.client1 }}
            </v-col>

            <v-col v-if="item.scores" cols="12" xs="4" sm="4" md="2" lg="2">
              <h4>Client2 Score</h4>
              {{ item.scores.client2 }}
            </v-col>

            <v-col v-if="item.scores" cols="12" xs="4" sm="4" md="2" lg="2">
              <h4>Client3 Score</h4>
              {{ item.scores.client3 }}
            </v-col>

            <v-col v-if="item.scores" cols="12" xs="4" sm="4" md="2" lg="2">
              <h4>Total Score</h4>
              {{ item.scores.total }}
            </v-col>

            <v-col v-if="item.scores" cols="12" xs="4" sm="4" md="2" lg="2">
              <h4>Average Score</h4>
              {{ item.scores.average }}
            </v-col>

            <v-col cols="12" xs="4" sm="4" md="4" lg="4">
              <h4>Changed By</h4>
              {{ item.changedBy }}
            </v-col>

            <v-col cols="12" xs="6" sm="6" md="4" lg="4">
              <h4>Skills</h4>
              {{ item.skills }}
            </v-col>

            <v-col cols="12" xs="6" sm="6" md="4" lg="4">
              <h4>Assigned To</h4>
              {{ item.assingedTo }}
            </v-col>

            <v-col cols="12" xs="4" sm="4" md="2" lg="2">
              <h4>Current CTC(in Lakhs)</h4>
              {{ item.currentCTC }}
            </v-col>

            <v-col cols="12" xs="4" sm="4" md="2" lg="2">
              <h4>Expected CTC(in Lakhs)</h4>
              {{ item.expectedCTC }}
            </v-col>

            <v-col cols="12" xs="4" sm="4" md="2" lg="2">
              <h4>Offered CTC</h4>
              {{ item.offeredCTC }}
            </v-col>

            <v-col cols="12" xs="4" sm="4" md="2" lg="2">
              <h4>Total Experence(in years)</h4>
              {{ item.totalExperience }}
            </v-col>

            <v-col cols="12" xs="4" sm="4" md="3" lg="3">
              <h4>Relavent Experence(in years)</h4>
              {{ item.relaventExperience }}
            </v-col>

            <!-- to display the attachment alone with delete options-->
            <v-col cols="12" xs="12" sm="12" md="6" lg="6">
              <h4>Files</h4>
              <Attachment
                v-for="(file, index) in item.resume"
                :key="file.filename"
                :file="file"
                :index="index"
                :close="false"
                :color="'green'"
              />
              <Attachment
                v-for="(file, index) in item.testreport"
                :key="file.filename"
                :file="file"
                :index="index"
                :close="false"
                :color="'green'"
              />
              <Attachment
                v-for="(file, index) in item.offer"
                :key="file.filename"
                :file="file"
                :index="index"
                :close="false"
                :color="'green'"
              />
            </v-col>
          </v-row>
        </td>
      </template>
      <template v-if="editflag" v-slot:[`item.actions`]="{ item }">
        <v-icon v-if="item.updateflag" small class="mr-2"> mdi-flag </v-icon>
        <v-icon small class="mr-2" @click.stop="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click.stop="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        <div><h2 class="blue">No data Found- Please add Profile</h2></div>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { recruitmentRef, usersRef, jobsRef, configgenRef, authdomain, clientprofilesRef } from "@/fb" // from db for collections
import { formMixin } from "@/mixins/formMixin.js"
import { emailMixin } from "@/mixins/emailMixin.js"
import { convertAll } from "@/mixins/convertall.js"
import { checkRoles } from "@/mixins/checkRoles.js"
import Upload from "@/components/common/Upload.vue"
import Attachment from "@/components/common/Attachment.vue"
import Snackbar from "@/components/common/snackbar.vue"
import Alertmsg from "@/components/common/Alertmsg.vue"
import Spinner from "@/components/common/spinner.vue"
import Slider from "@/components/common/Slider.vue"
import tipTapVuetify from "@/components/common/tipTapVuetify.vue"
import { collectionsOperation } from "@/mixins/collectionsOperations.js"
import TimePicker from "@/components/common/TimePicker.vue"
import DatePicker from "@/components/common/Datepicker.vue"
import { json2excel } from "js2excel"
import {mapState} from "vuex"
export default {
  name: "Profiles",
  //props:['roles'],
  mixins: [formMixin, emailMixin, convertAll, checkRoles, collectionsOperation],
  components: {
    Upload,
    Attachment,
    Snackbar,
    Alertmsg,
    Spinner,
    tipTapVuetify,
    Slider,
    TimePicker,
    DatePicker,
  },

  data: () => ({
    date: null,
    menuDOS: false, //date of sign
    menuIT: false, //eventtime
    menuID: false, //eventdate
    dialog: false,

    search: "", //for data table serch.
    domain: "",
    email: "",
    notloaded: true,
    singleExpand: false,
    expanded: [],
    expandflag: true,
    userconfig: {},
    itemsPerPage: 10,

    lazy: false,
    valid: true,
    // viewdocs:["resume","testreport","offer"],
    nowDate: new Date().toISOString().slice(0, 10),
    headers: [
      {
        text: "Id",
        align: "start",
        sortable: true,
        value: "jobId",
      },
      { text: "Job Title", value: "title", sortable: true },
      { text: "Name ", value: "candidateName", sortable: true },
      { text: "Mobile Number", value: "candidateNumber", sortable: true },
      { text: "Last Change", value: "changedAt", sortable: true },
      { text: "Status", value: "status", sortable: true },
      { text: "Profile Status", value: "pStatus", sortable: true },
      { text: "Email", value: "email", align: " d-none" },
      { text: "Notice", value: "noticePeriod", align: " d-none" },
      { text: "Skills", value: "skills", align: " d-none" },
      { text: "Created", value: "createdAt", align: " d-none" },
      { text: "Changed", value: "changedBy", align: " d-none" },
      { text: "Assigned", value: "assingedTo", align: " d-none" },
      { text: "Current", value: "currentCTC", align: " d-none" },
      { text: "Expected", value: "expectedCTC", align: " d-none" },
      { text: "Offered", value: "offeredCTC", align: " d-none" },
      { text: "Total", value: "totalExperience", align: " d-none" },
      { text: "relaventExperience", value: "relaventExperience", align: " d-none" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    recruitment: [], //main array only for open jobs id's
    downloadArray:[],
    //showrecruitment: [], // data table display array
    allprofile: [], // will hold the data for all profile
    editedIndex: -1,
    name: "",
    allnames: [], // for assingedTo
    allemails: [],

    interviewerslist: [], // for assingedTo

    chips: [],
    jobids: [], // for all jobs ids.
    jobdetails: [], // for all jobs details.

    editedItem: {
      jobId: null,
      title: null,
      candidateName: null,
      candidateNumber: null,
      resume: [],
      testreport: [],
      offer: [],
      offerletters: [],
      noticePeriod: null,
      status: "Profile Created",
      pStatus:"Open",
      createdAt: null,
      createdBy: null,
      changedAt: null,
      changedBy: null,
      assingedTo: null,
      oldassingedTo: null,
      source: null,
      sourcename:null,
      test: null,
      skills: [],
      hrcomments: null,
      manangercomments: null,
      clientcomments: null,
      clientcomments2: null,
      domain: null,
      relaventExperience: null,
      totalExperience: null,
      expectedCTC: null,
      currentCTC: null,
      offeredSalary: false,
      offeredCTC: null,
      prestatus: null,
      email: null,
      VariablePay: null,
      bpratio: null,
      professionalTax: null,
      offergiven: false,
      offerletterurl: null,
      sigingdate: null,
      eventdate: null,
      eventtime: null,
      scores: { test: 0, manager: 0, client1: 0, client2: 0, total: 0, average: 0 },
      processflow: [],
      DateTimes: {
        manager: { date: "", time: "" },
        client1: { date: "", time: "" },
        client2: { date: "", time: "" },
        client3: { date: "", time: "" },
        joining: { date: "", time: "" },
      },
      interviewers: {
        manager: { name: "", email: "" },
        client1: { name: "", email: "" },
        client2: { name: "", email: "" },
        client3: { name: "", email: "" },
      },
    }, // end of editedItem

    defaultItem: {}, // end of defaultItem
    // don't change the below order it is being used in saveitem functions.
    allStatus: [], // for store the list of status of the profile.

    tmpallStatus: [
      "Profile Created",
      "HR Discussion",
      "No Response",
      "Not Interested",
      "Test",
      "Test Selected",
      "Test Rejected",
      "Manager Interview",
      "Comeet uploaded",
      "Comeet Shortlisted",
      "Comeet Rejected",
      //"Client Interview",
      "Client1 Interview - Shortlisted",
      "Client1 Interview - Rejected",
      "Client2 Interview - Shortlisted",
      "Client2 Interview - Rejected",
      "Client3 Interview - Shortlisted",
      "Client3 Interview - Rejected",
      "Manager Interview",
      //"Client1 Interview",
      //"Client2 Interview",
      "Selected",
      "Offer Printed",
      "Offer Sent",
      "Offer Accepted",
      "Joined",
      "Not Joined",
      "Not Selected",
      "Pending",
      "Closed",
    ], // don't change the order it is being used in saveitem functions.
    alltabstatus: [
      "All Open",
      "Profile Created",
      "HR Discussion",
      "No Response",
      "Not Interested",
      "Test",
      "Test Selected",
      "Test Rejected",
      "Manager Interview",
      "Comeet uploaded",
      "Comeet Shortlisted",
      "Comeet Rejected",
     // "Client Interview",
      "Client1 Interview - Shortlisted",
      "Client1 Interview - Rejected",
      "Client2 Interview - Shortlisted",
      "Client2 Interview - Rejected",
      "Client3 Interview - Shortlisted",
      "Client3 Interview - Rejected",
      "Selected",
      "Offer Printed",
      "Offer Sent",
      "Offer Accepted",
      "Joined",
      "Not Joined",
      "Not Selected",
      "Pending",
      "Closed",
      "All Profile",
    ], // to display the list of changes in main page status tab.
    pStatusValues:["Open", "Closed"],
    tabstatus: "",
    statuscount: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // for dashboard.
    //testreporturl:'',
    sources: ["Employee Referral", "Other Referral", "Job Portals", "LinkedIn", "Direct Application"], // for profile creations source list.
    //tests:["Needed","Not Needed"], // the profile requires test or not
    editflag: false,
    backmenu: "HRadmin",
    editorDisabled: true,
    // for rich text box variables.

    mailmsg: {
      to: "",
      subject: "",
      message: "",
    },
    decimalinput: [(v) => !!v || "Input is required", (v) => /^[0-9,.,,\s]+$/.test(v) || "Only Numbers and . allowed"],
    numberinput: [(v) => !!v || "Input is required", (v) => /^[0-9\s]+$/.test(v) || "Only Numbers  allowed"],
    inputrequired: [(v) => !!v || "Input is required"],
    emailRules: [(v) => !!v || "E-mail is required", (v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
    snackbar: false,
    alertflag: false,
    alertmain: false,
    alertmsg: "",
    snackbartext: "",
    spinner: false,
    jobnotselected: true,
    openjobids: [],
    dashboardarray: [],
    //dashboardflag: false,
    roles: "",
    notaddvalues: true, // to fetch data from emptable for manager names and open job id's -one time only
    inteviewdatetime: null,
    clientprofileupdateuid: [],
    uploading: false,
    placeholder: "Click here to Select Files to Attach",
    files: "",
    taboption: "Candidate Details",
   // candidatedetails: true,
   // testreports: false,
   // interviewcomments: false,
    reportsarray: "",
    reportscolumns: "",
    currentstatus: "",
    allmenus: false,
    
  }), // end of data

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Profile" : "Edit Profile"
    },
    getnoticePeriodrequiredOption() {
      console.log("inside getnoticePeriodrequiredOption", this.editedItem.source)
      return this.editedItem.source === "Job Portals" ? true : false
    },
    geteditflag() {
      return true // for tiptap
    },

    // to show the different color while client1 interview changes and score
    checkupdateflagclient1() {
      console.log("inside checkupdateflag", this.editedItem.updateflag)
      return this.editedItem.updateflag && this.editedItem.status == "Client1 Interview" ? "blue" : ""
    },
    // to show the different color while client1 interview changes and score
    checkupdateflagclient2() {
      console.log("inside checkupdateflag", this.editedItem.updateflag)
      return this.editedItem.updateflag && this.editedItem.status == "Client2 Interview" ? "blue" : ""
    },
    checkupdateflagclient3() {
      console.log("inside checkupdateflag", this.editedItem.updateflag)
      return this.editedItem.updateflag && this.editedItem.status == "Client3 Interview" ? "blue" : ""
    },

    storagePath_testreport() {
      var testrportstoreagepath = "testreport/" + this.domain + "_" + this.editedItem.jobId + "_"
      return testrportstoreagepath
    },
    storagePath_resume() {
      var testrportstoreagepath = "resume/" + this.domain + "_" + this.editedItem.jobId + "_"
      return testrportstoreagepath
    },
    storagePath_offer() {
      var testrportstoreagepath = "offers/" + this.domain + "_" + this.editedItem.jobId + "_"
      return testrportstoreagepath
    },
    ...mapState({
      currentDomain: state=> state.userProfile.domain
    }),

    getdatatablearray(){
      var tmparray=[];
      switch (this.currentstatus){
        case "All Open":tmparray=this.recruitment.filter((re) => re.status != "closed" && re.openjobid==true);break
        case "All Profile":tmparray=this.recruitment;break
        case "tasklist":tmparray= this.recruitment.filter((re) =>
                                                          re.assingedTo == this.email &&
                                                          re.status != "Not Selected" &&
                                                          re.status != "Closed" &&
                                                          re.status != "Not Interested" &&
                                                          re.openjobid==true
                                                          );
                                                          break
        case "Client Interview":tmparray=this.recruitment.filter((re) => re.openjobid==true && (re.status=="Client1 Interview" || re.status=="Client2 Interview" || re.status=="Client3 Interview"));break
        default:tmparray=this.recruitment.filter((re) => re.openjobid==true && re.status == this.currentstatus)


      }
      return tmparray;
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    //scores: { test: 0, manager: 0, client1: 0, client2: 0, total: 0, average: 0 },
    "editedItem.scores.client1": function () {
      this.calculatescores()
    },
    "editedItem.scores.manager": function () {
      this.calculatescores()
    },
    "editedItem.scores.client2": function () {
      this.calculatescores()
    },
    "editedItem.scores.client3": function () {
      this.calculatescores()
    },
  },

  async created() {
    this.roles = await this.$store.state.userProfile // take the user logged info from state
    console.log("recruitment crated", this.roles)
    if (this.roles) {
      if (this.roles.role.hradmin || this.roles.role.readuser || this.roles.role.recruitment) {
        if (this.roles.role.hradmin || this.roles.role.recruitment) {
          // only hradmin can have access to this page.
          this.editflag = true
        }
        if (this.roles.role.hradmin || this.roles.role.readuser) {
          this.allmenus = true
        }
        this.domain = this.roles.domain
        this.name = this.roles.name
        this.email = this.roles.email
        this.userconfig = this.roles.userconfig
        this.itemsPerPage = this.roles.itemsPerPage
        console.log("itemsPerPage", this.itemsPerPage, this.roles.itemsPerPage)

        this.defaultItem = Object.assign({}, this.editedItem)
        await this.checkclientupdate() // check if any client update for an profile and take the uid for same
        await this.initialize() // to get the list of profiles
        console.log("this.notloaded", this.notloaded)
      } else {
        alert("Not authorized to access this page , kindly contact support")
        //this.$router.replace({ name: this.roles.menufrom})
        this.$router.push({ name: this.roles.menufrom, params: { roles: this.roles } })
      }
    } else {
      alert("Not authorized to access this page , kindly contact support")
      this.$router.replace({ name: "/login" })
    }
  },
   mounted() { 
    //var tmpalertflag = false
       clientprofilesRef.onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.doc.data().domain == this.domain) {
        
          this.updaterealtimedata(this.recruitment, change)
        } //end of this.domain*/
      }) // end of for each
    }) // end of snapshot

      recruitmentRef.where("domain", "==", this.currentDomain).onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
     
       //   console.log("inside mounted",this.notloaded, change.type)
          if(!this.notloaded){
           //this.updaterealtimedata(this.recruitment, change)
             var openjobid;
            if(this.openjobids.indexOf(change.doc.data().jobId)>-1){
               openjobid=true
            }
            else openjobid=false
            var objIndex = this.recruitment.findIndex((obj) => obj.uid == change.doc.id)
            if (change.type === "modified") {
              Object.assign(this.recruitment[objIndex], change.doc.data())
              this.recruitment[objIndex].openjobid=openjobid;
              this.recruitment[objIndex].updateflag=true;
            } else if (change.type === "added") {
              if (objIndex < 0)
                this.recruitment.push({
                  ...change.doc.data(),
                  uid: change.doc.id,
                  openjobid:openjobid,
                  updateflag:true,
                })
            } else if (change.type === "removed") {
              this.recruitment.splice(objIndex, 1)
            }
          }// end of if(!this.notloaded)
                
     
      }) // end of for each
    }) // end of snapshot

 
  },

  methods: {
    // the randomcolor for dashboard display
    randomColor() {
      const colorsList = [
        "grey",
        "deep-orange",
        "brown",
        "blue-grey",
        "orange",
        "green",
        "cyan",
        "teal",
        "blue",
        "indigo",
        "pink",
        "red",
        "purple",
      ]
      const rand = Math.floor(Math.random() * colorsList.length)
      return colorsList[rand]
    },

    // check is any record added in client profile table .if any new record added it will update into main table and delete the records.
    async checkclientupdate() {
      this.clientprofileupdateuid = []
      var queryresult = await clientprofilesRef.get()
      for (var i = 0; i < queryresult.docs.length; ++i) {
        console.log("inside checkclientupdate")
        var tmpuid = queryresult.docs[i].data().uid
        await recruitmentRef.doc(tmpuid).update({
          scores: queryresult.docs[i].data().scores,
          clientcomments: queryresult.docs[i].data().clientcomments,
          clientcomments2: queryresult.docs[i].data().clientcomments2,
          // updatedflag:true
        })
        this.clientprofileupdateuid.push({
          uid: tmpuid,
        })
      }
      // clientprofilesRef.delete;
      for (i = 0; i < queryresult.docs.length; ++i) {
        console.log("inside delete", queryresult.docs[i].id)
        clientprofilesRef.doc(queryresult.docs[i].id).delete()
      }
    },

    // the below will calculate the total score and average score based on manager and client scores.
    calculatescores() {
      var denominator = 4
      this.editedItem.scores.total =
        this.editedItem.scores.manager + this.editedItem.scores.client1 + this.editedItem.scores.client2 + this.editedItem.scores.client3

      if (this.editedItem.scores.manager == 0) denominator--
      if (this.editedItem.scores.client1 == 0) denominator--
      if (this.editedItem.scores.client2 == 0) denominator--
      if (this.editedItem.scores.client3 == 0) denominator--
      if (denominator != 0 && this.editedItem.scores.total != 0) {
        var tmpaverage = this.editedItem.scores.total / denominator
        this.editedItem.scores.average = tmpaverage.toFixed(2)
      } else this.editedItem.scores.average = 0
    },

    // to set the which tab currently the user is and set the repective flag as true. these flages are handled in main html codes.
    /*settaboption(taboption) {
      this.taboption = taboption
      console.log("taboption", this.taboption)
      ;(this.candidatedetails = false), (this.testreports = false), (this.interviewcomments = false)

      if (this.taboption == "Candidate Details") {
        this.candidatedetails = true
      } else if (this.taboption == "Test Report") {
        this.testreports = true
      } else if (this.taboption == "Intervew Comments") {
        this.interviewcomments = true
      }
    },*/
    // the below will check the selected status of tabs and set the showrecruitment with repective status.
    setstatus(status) {
      console.log("setstatus", status)
      this.currentstatus = status
    
      if (status == "dashboard") {
        this.getdashboarddetails()
      } else if (status == "Export") {
        this.setreports()
      }
     
    },

    async checkstatus() {
      console.log("inside checkstatus", this.editedItem.status)
      // if the status is seleced then it will get the basic pay ratio from configuration table.
      if (this.editedItem.status == "Offer Printed") {
        this.editedItem.offeredSalary = true // to add few more fields like offered salary and Professional tax, variable pay.
        this.editedItem.offergiven = false
        var queryresult = await configgenRef
          .where("domain", "==", this.domain)
          .where("description", "==", "BPRatio")
          .get()
        if (queryresult.docs.length > 0) {
          console.log("inside checkstatus", queryresult.docs[0].data())
          this.editedItem.bpratio = queryresult.docs[0].data().value
          console.log("bpratio", queryresult.docs[0].data().value, this.editedItem.bpratio)
        }
      }
      // if the status is either of interview then set the assigned to as NULL as the user forget to change the assignedto name and interview date and time as null;
      else if (
        this.editedItem.status == "Manager Interview" ||
        this.editedItem.status == "Client1 Interview" ||
        this.editedItem.status == "Client2 Interview" ||
        this.editedItem.status == "Client3 Interview" ||
        this.editedItem.status == "Joined"
      ) {
        this.editedItem.assingedTo = null
        this.editedItem.eventdate = null
        this.editedItem.eventtime = null
      }
      // to check the offer attached into the profile.
      else if (this.editedItem.status == "Offer Sent") {
        this.editedItem.offeredSalary = false
        this.editedItem.offergiven = true
        this.editedItem.offerletterurl = this.editedItem.offer[0].fileurl
        console.log("inside offersent ", this.editedItem.offerletterurl)
      } else {
        this.editedItem.offeredSalary = false
        this.editedItem.offergiven = false
      }
    },
    //  it will add the attachment values in the repective array.
    addAttachment(options, fileDetails) {
      if (options == "TestReports") this.editedItem.testreport.push({ ...fileDetails })
      else if (options == "Resume") this.editedItem.resume.push({ ...fileDetails })
      else if (options == "Offers") this.editedItem.offer.push({ ...fileDetails })
    },
    //  it will remove the attachment values in the repective array.
    removeAttachment(options, fileDetails) {
      console.log("inside removeAttachment options", options)
      console.log("inside removeAttachment fileDetails", fileDetails)
      if (options == "TestReports") {
        let files = this.editedItem.testreport.filter((v) => v.filename != fileDetails.filename)
        this.editedItem.testreport = [...files]
      } else if (options == "Resume") {
        let files = this.editedItem.resume.filter((v) => v.filename != fileDetails.filename)
        this.editedItem.resume = [...files]
      } else if (options == "Offers") {
        let files = this.editedItem.offer.filter((v) => v.filename != fileDetails.filename)
        this.editedItem.offer = [...files]
      }
    },
    // once the job is selected it will take the job id and parse the jobid ,title, scores and process flow from main jobdetails array
    selectedJobs() {
      console.log("inside selectjobs", this.editedItem.jobId)
      var res = this.editedItem.jobId.split("::")
      this.editedItem.jobId = ""
      var tmpjobid = res[0]

      var filteredItems = this.jobdetails.filter((jobdetail) => {
        return jobdetail.jobid == tmpjobid
      })

      this.editedItem.jobId = filteredItems[0].jobid
      this.editedItem.title = filteredItems[0].title
      this.editedItem.test = filteredItems[0].test
      if (filteredItems[0].processflow) {
        var tmpprocessflow = filteredItems[0].processflow
        this.allStatus = ["Profile Created", "HR Discussion", "No Response", "Not Interested", "Not Selected",
          "Commet uploaded","Commet Shortlisted","Commet Rejected","Test Selected","Test Rejected",
          "Client1 Interview - Shortlisted","Client1 Interview - Rejected",
          "Client2 Interview - Shortlisted","Client2 Interview - Rejected",
          "Client3 Interview - Shortlisted","Client3 Interview - Rejected",
        ]
        
        if (tmpprocessflow.flow1 != null) this.allStatus.push(tmpprocessflow.flow1)
        if (tmpprocessflow.flow2 != null) this.allStatus.push(tmpprocessflow.flow2)
        if (tmpprocessflow.flow3 != null) this.allStatus.push(tmpprocessflow.flow3)
        if (tmpprocessflow.flow4 != "") this.allStatus.push(tmpprocessflow.flow4)
        this.allStatus.push("Selected")
      //  this.allStatus.push("Print Offer")
        this.allStatus.push("Offer Printed"), this.allStatus.push("Offer Sent")
        this.allStatus.push("Offer Accepted")
        this.allStatus.push("Joined")
        this.allStatus.push("Not Joined")
        this.allStatus.push("Pending")
        this.allStatus.push("Closed")

        this.editedItem.processflow = JSON.parse(JSON.stringify(this.allStatus))
      } else {
        this.allStatus = JSON.parse(JSON.stringify(this.tmpallStatus))
        this.editedItem.processflow = JSON.parse(JSON.stringify(this.allStatus))
      }
      this.jobnotselected = false
    },
    // initialize the main array
    async setreports() {
      await this.createreports()
      console.log("End of create report")

      var data = JSON.parse(JSON.stringify(this.reportsarray))

      try {
        json2excel({
          data,
          name: "Profile",
          formateDate: "yyyy/mm/dd",
        })
      } catch (e) {
        console.error("export error", e)
      }
    }, // end of createreports

    async removehtmltags(htmltagtext) {
      var newtext = ""
      newtext = await htmltagtext.replace(
        /(<u>|<\/u>|<s>|<\/s>|<ul>|<\/ul>|<em>|<\/em>|<strong>|<\/strong>|<\/strong>)/g,
        ""
      )
      newtext = await newtext.replace(/(<\/p>|<ol>|<\/ol>|<li>|<\/li>|<&nbsp;>)/g, " ")
      newtext = await newtext.replace(/(<p>)/g, "\n")
      return newtext
    },

    async createreports() {
      this.reportsarray = []

      console.log("inside setreports")
      for (var i = 0; i < this.recruitment.length; ++i) {
        var managerComments = ""
        var tmpcomments = ""
        var client1Comments = ""
        var client2Comments = ""
        var client3Comments = ""

        if (this.recruitment[i].manangercomments != null) {
          tmpcomments = await this.removehtmltags(this.recruitment[i].manangercomments)
          managerComments = this.recruitment[i].interviewers.manager.name + ":" + tmpcomments
        }
        if (this.recruitment[i].clientcomments != null) {
          tmpcomments = await this.removehtmltags(this.recruitment[i].clientcomments)
          client1Comments = this.recruitment[i].interviewers.client1.name + ":" + tmpcomments
        }
        if (this.recruitment[i].clientcomments2 != null) {
          tmpcomments = await this.removehtmltags(this.recruitment[i].clientcomments2)
          client2Comments = this.recruitment[i].interviewers.client2.name + ":" + tmpcomments
        }
        if (this.recruitment[i].clientcomments3 != null) {
          tmpcomments = await this.removehtmltags(this.recruitment[i].clientcomments3)
          client3Comments = this.recruitment[i].interviewers.client3.name + ":" + tmpcomments
        }
        //added the profile status in the report.
        var pStatus='';
        if( Object.prototype.hasOwnProperty.call(this.recruitment[i],'pStatus')) {
          pStatus=this.recruitment[i].pStatus
        }
        /* the below codes were develoed to change the job title based on the Rajesh request */
       
       /* if(this.recruitment[i].jobId=="102" || this.recruitment[i].jobId=="201" || this.recruitment[i].jobId=="106" ){
          var newtitle
          switch (this.recruitment[i].jobId){
            case  "102" : newtitle="S_QA Automation"; break;
            case "201" :  newtitle="BDE"; break;
            case "106" :  newtitle="S_ETL QA Lead"; break;
           }// end of switch
           await recruitmentRef.doc(this.recruitment[i].uid).update({
                title:newtitle
            }).then(() =>{
              console.log("update sucessfull",this.recruitment[i].uid,this.recruitment[i].jobId,newtitle )
            }).catch(error =>{
              console.log("error in update",this.recruitment[i].uid,this.recruitment[i].jobId,error)
            })
          }*/

        /* end of change in Job title*/
  
     /*  
        // await recruitmentRef.doc(this.recruitment[i].uid).update({
        //   pStatus:'Open'
        // })*/

        /*var cutoffDate= "2021-07-31"
  
        if(cutoffDate >= this.recruitment[i].createdAt && pStatus!="Closed" 
        ){
        console.log(this.recruitment[i].createdAt,pStatus,this.recruitment[i].uid)
         await recruitmentRef.doc(this.recruitment[i].uid).update({
                pStatus:"Closed"
            }).then(() =>{
              console.log("update sucessfull",this.recruitment[i].uid )
            }).catch(error =>{
              console.log("error in update",this.recruitment[i].uid,error)
            })
        }*/



        this.reportsarray.push({
          SNO: i + 1,
          JobID: this.recruitment[i].jobId,
          Title: this.recruitment[i].title,
          CandidateName: this.recruitment[i].candidateName,
          TestResult: this.recruitment[i].scores.test,
          ManagerInterviewDate: this.convertDate(this.recruitment[i].DateTimes.manager.date),
          Clinet1InterviewDate: this.convertDate(this.recruitment[i].DateTimes.client1.date),
          Client2InterviewDate: this.convertDate(this.recruitment[i].DateTimes.client2.date),
          Client3InterviewDate: this.convertDate(this.recruitment[i].DateTimes.client3.date),
          NoticePeriod: this.recruitment[i].noticePeriod,
          status: this.recruitment[i].status,
          ProfileStatus: pStatus,
          CreatedOn: this.recruitment[i].createdAt,
          ChangedOn: this.recruitment[i].changedAt,
          commencementdate: this.recruitment[i].DateTimes.joining.date,
          ManagerComments: managerComments,
          Client1Comments: client1Comments,
          Client2Comments: client2Comments,
          client3Comments:client3Comments
        })
      } // end of for recruitment
    }, // end of createreports
    async initialize() {
      this.recruitment = []
      this.allprofile = []
      this.downloadArray=[]
     
      //var tmpSkills=[];
      await this.getopenjobids()
      await recruitmentRef
        .where("domain", "==", this.domain)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            var tmpprocessflow
            tmpprocessflow = doc.data().processflow
            // if there is no process flow for that profile then assign the default process flow.
            if (!tmpprocessflow) {
            //  console.log("process flow not found", doc.data().jobId)
              tmpprocessflow = JSON.parse(JSON.stringify(this.tmpallStatus))
            }
            var tmpscore = doc.data().scores
            // if there is no score for that profile then assign the default score.
            if (tmpscore && !tmpscore.client3)
            tmpscore.client3=0
            if (!tmpscore) {
              tmpscore = { manager: 0, client1: 0, client2: 0, client3: 0,total: 0, average: 0 }
            }
            var tmpdates = doc.data().DateTimes
            if(tmpdates && !tmpdates.client3)
            tmpdates.client3= { date: "", time: "" }
            if (!tmpdates) {
             
              tmpdates = {
                manager: { date: "", time: "" },
                client1: { date: "", time: "" },
                client2: { date: "", time: "" },
                client3: { date: "", time: "" },
                joining: { date: "", time: "" },
              }
              // console.log("inside DateTimes Null ",tmpdates,doc.id)
            }
            var tmpinterviewers = doc.data().interviewers
            if (!tmpinterviewers) {
              tmpinterviewers = this.editedItem.interviewers
              // console.log("inside tmpinterviewers Null ",tmpinterviewers,doc.id)
            }
            // check if any client updated in client profile then set the updateflag as true to show the alert message.
            var updateflag = false
            var tmprecruitment = this.clientprofileupdateuid.filter((re) => re.uid == doc.id)

            if (tmprecruitment.length > 0) {
              updateflag = true
            }
            var openjobid;
            if(this.openjobids.indexOf(doc.data().jobId)>-1){
               openjobid=true
            }
            else openjobid=false
              
            var tmpidx = this.recruitment.findIndex((obj) => obj.uid == doc.id)
            if(tmpidx<0){
             /* this.downloadArray.push({
                jobId:doc.data().jobId,
                title:doc.data().title,
                CandidateName:doc.data().candidateName,               
                CandidateNumber:doc.data().candidateNumber,
                email:doc.data().email,
                totalExperience:doc.data().totalExperience,
                relaventExperience:doc.data().relaventExperience,
                currentCTC:doc.data().currentCTC,
                expectedCTC:doc.data().expectedCTC,
                status:doc.data().status,
                createdAt:doc.data().createdAt,
                createdBy:doc.data().createdBy,
                ChangedAt:doc.data().changedAt,
                changedBy:doc.data().changedBy,
                AssingedTo:doc.data().assingedTo,
                noticePeriod:doc.data().noticePeriod,
                hrcomments:doc.data().hrcomments,
                manangercomments:doc.data(),manangercomments,
                client1comments:doc.data().clientcomments,
                client2comments:doc.data().clientcomments2,
                eventdate:doc.data().eventdate(),
              })*/
            
            this.recruitment.push({
              ...doc.data(),
              uid: doc.id,
              DateTimes: tmpdates,
              interviewers: tmpinterviewers,
              scores: tmpscore,
              editing: false,
              updateflag: updateflag,
              processflow: tmpprocessflow,
              openjobid:openjobid
            })
          }
            else{
              this.recruitment[tmpidx] = doc.data();
              this.recruitment[tmpidx].DateTimes = tmpdates;
              this.recruitment[tmpidx].interviewers = tmpinterviewers;
              this.recruitment[tmpidx].scores = tmpscore;
              this.recruitment[tmpidx].processflow = tmpprocessflow;
              this.recruitment[tmpidx].updateflag = updateflag;
              this.recruitment[tmpidx].editing = false;
              this.recruitment[tmpidx].uid = doc.id;

            }

         
          }) // end of for each in recruitmentRef
        /*  var dataArray = JSON.parse(JSON.stringify(this.downloadArray))
          try {
        json2excel({
          dataArray,
          name: "ProfileDetails",
          formateDate: "yyyy/mm/dd",
        })
      } catch (e) {
        console.error("export error", e)
      }*/

          this.notloaded = false
          //this.currentstatus = "tasklist"
          this.currentstatus="All Open"
        }) //end of recruitmentRef get
        .catch((err) => console.log(err))
    },
    // once the add profile button clicks then this will invoke once.
    // 1. will get the list of open jobs ids and creates jobs ids array,jobdetails.
    // 2. query the emp record and creats the names and email id's array
    async getaddvalues() {
    
      this.editedItem.testreport = []
      this.editedItem.resume = []
      this.editedItem.offer = []
      console.log("inside getaddvalues", this.editedItem)
      //this.editedItem={};
      if (this.notaddvalues) {
        this.jobids = []
        this.jobdetails = []

        await jobsRef
          .where("domain", "==", this.domain)
          .where("status", "==", "open")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.jobids.push(doc.data().jobid + "::" + doc.data().title)
              this.jobdetails.push({
                ...doc.data(),
                uid: doc.id,
              })
            })
          })
          .catch((err) => console.log(err.message))
        if (this.allemails.length == 0) this.getallnames()
        this.notaddvalues = false
      }
    },
    // query the emp record and creats the names and email id's array
    async getallnames() {
      this.allemails = []
      this.interviewerslist = []
      await usersRef
        .where("domain", "==", this.domain)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if (doc.data().role.includes("hradmin") || doc.data().role.includes("recruitment"))
              this.interviewerslist.push({ email: doc.data().email, name: doc.data().name })
          })
          this.allemails = this.interviewerslist.map((a) => a.email)
        })
        .catch((err) => console.log(err.message))
    },
    // for dash board display.
    async getopenjobids() {
      var totalOpenarray = []
      this.dashboardarray = []
      this.openjobids = []
      var newcounts = []

      var tmpcount = [
        { dbstatus: "Profile Created", count: 0 },
        { dbstatus: "HR Discussion", count: 0 },
        { dbstatus: "Test", count: 0 },
        { dbstatus: "Manager Interview", count: 0 },
        { dbstatus: "Client1 Interview", count: 0 },
        { dbstatus: "Client2 Interview", count: 0 },
        { dbstatus: "Client3 Interview", count: 0 },
        { dbstatus: "Selected", count: 0 },
        { dbstatus: "Not Selected", count: 0 },
        { dbstatus: "Pending", count: 0 },
        { dbstatus: "Closed", count: 0 },
        { dbstatus: "Offer Accepted", count: 0 },
        { dbstatus: "Joined", count: 0 },
      ]
      newcounts = JSON.parse(JSON.stringify(tmpcount))
      await jobsRef
        .where("domain", "==", this.domain)
        .where("status", "==", "open")
        .orderBy("jobid", "asc")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.openjobids.push(doc.data().jobid)
            totalOpenarray.push({
              jobid: doc.data().jobid,
              title: doc.data().title,
              totalOpen: doc.data().totalOpen,
            })
            // creates the array of each open job id's and set the status counts as zero for all status.
            this.dashboardarray.push({
              jobId: doc.data().jobid,
              title: doc.data().title,
              totalOpen: doc.data().totalOpen,
              currentOpen: doc.data().totalOpen,
              statuscounts: newcounts,
            })
          })
        })
    }, // end of getopenjobids
    async getdashboarddetails() {
      var tmparray = []
      var closedstatus = ["Offer Accepted", "Joined", "Closed"] //  array for closed status.
      // get the list of open jobs id's
      await this.getopenjobids()
      // get the profile list based on the open jobs id's
      var tmpopenjobids = []
      // the below for loops added as the firebase accepts only 10 values in "in" constrain
      // the below will break the openjobsid array as sequence of 10 and query the table.
      for (var i = 0; i < this.openjobids.length / 10; ++i) {
        tmpopenjobids = []
        for (var j = i * 10; j < (i + 1) * 10 && j < this.openjobids.length; ++j) tmpopenjobids.push(this.openjobids[j])
        let query = recruitmentRef.where("domain", "==", this.domain).where("jobId", "in", tmpopenjobids)
        await query.get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            var insertFlag=true;
            if( Object.prototype.hasOwnProperty.call(doc.data(),'pStatus') && doc.data().pStatus=="Closed"){
              insertFlag=false;
            }
            if(insertFlag)
            tmparray.push({
              jobId: doc.data().jobId,
              title: doc.data().title,
              status: doc.data().status,
            })
          })
        })
      } // end of for i
      //console.log("tmparray",tmparray)
      for (i = 0; i < tmparray.length; ++i) {
        var tmpidx = this.dashboardarray.findIndex((obj) => obj.jobId == tmparray[i].jobId) // get the index for job ids for the profile job list.
        // console.log("tmparray and idx",tmpidx,tmparray[i].jobId,tmparray[i].title,tmparray[i].status)
        if (tmpidx > -1) {
          // job id  found in prfoile
          var tmpstatusarray = JSON.parse(JSON.stringify(this.dashboardarray[tmpidx].statuscounts)) // assign the current status counts into tmpstatus array.
          var statusarrayindex = tmpstatusarray.findIndex((obj) => obj.dbstatus == tmparray[i].status) // get the index of status of profile from tmpstatus array.
          if (statusarrayindex > -1) {
            //  found the status
            var nc = tmpstatusarray[statusarrayindex].count + 1 // increment the status count as one.
            tmpstatusarray[statusarrayindex].count = nc // assigned to tmpstatus array
            this.dashboardarray[tmpidx].statuscounts = tmpstatusarray // assign the tmpstaus to main dashboard array
          }
          if (closedstatus.indexOf(tmparray[i].status) > -1) {
            // if the status is one of closed status
            nc = this.dashboardarray[tmpidx].currentOpen - 1 // decrement the total opening as 1.
            this.dashboardarray[tmpidx].currentOpen = nc //assinged to main array
          }
          tmpstatusarray = Object.assign({}, null)
        }
      }
      console.log("dashboardarray", this.dashboardarray)
    }, // end of getdashboarddetails

    editItem(item) {
      this.editedIndex = this.recruitment.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
      this.jobnotselected = false
      this.editedItem.prestatus = this.editedItem.status

      this.editedItem.oldassingedTo = this.editedItem.assingedTo
      console.log(
        "in edit item ",
        this.editedItem.prestatus,
        this.editedItem.status,
        this.editedItem.oldassingedTo,
        this.editedItem.assingedTo
      )
      this.taboption="Candidate Details"
      //;(this.candidatedetails = true), (this.testreports = false), (this.interviewcomments = false)
      console.log("inside ediited item", this.editedItem.scores,this.taboption)
      this.allStatus = JSON.parse(JSON.stringify(this.editedItem.processflow))

      if (this.allemails.length == 0) this.getallnames()
    },

    async deleteItem(item) {
      const index = this.recruitment.indexOf(item)
     // const index1 = this.showrecruitment.indexOf(item)
      const deletemsg = "Are you sure you want to delete this profile?" + item.candidateName
      const option = await this.deleteFromFb(deletemsg)
      if (option) {
        try {
          await recruitmentRef.doc(item.uid).delete()
          console.log("inside delete Item", index)
          await this.recruitment.splice(index, 1)
         // await this.showrecruitment.splice(index1, 1)
        } catch (error) {
          console.log("Error while delete :", error)
          this.snackbar = true
          this.snackbartext = "Error while delete" + error.message
        }
      }
    },
    close() {
      this.dialog = false
      this.jobnotselected = true
     // this.candidatedetails = false
      this.spinner = false
      console.log("inside close", this.editedItem.updateflag)
      if (this.editedItem.updateflag) this.recruitment[this.editedIndex].updateflag = false //since update flag is not defined is newitem (defualt item and edit item) checking before setting .
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    checkmobilenumber() {
      var tmprecruitment = this.recruitment.filter((re) => re.candidateNumber == this.editedItem.candidateNumber)

      if (tmprecruitment.length > 0) {
        this.snackbar = true
        this.snackbartext = "Mobile Number already exists please search the profile"
      }
    },

    async saveitem() {
      var asssignedlistname = " "
      var asssignedlistemail = " "

      console.log("inside save item", this.editedItem)
      var asssignedlist = this.interviewerslist.filter((re) => re.email == this.editedItem.assingedTo) // to take the name and email id of the assigned to email
      console.log("saveitem-assingedlist", asssignedlist)
      if (this.editedIndex > -1) {
        var statusindex = this.allStatus.indexOf(this.editedItem.status)
        var testindex = this.allStatus.indexOf("Test")
        if (this.editedItem.test == "Needed" && this.editedItem.testreport.length <= 0 && statusindex > testindex) {
          this.alertmsg = "Please attach test reports"
          this.alertflag = true
          this.spinner = false
        }
        else if (
          (this.editedItem.status.includes("Interview") || this.editedItem.status == "Joined") &&
          (this.editedItem.eventdate == null || this.editedItem.eventtime == null)
        ) {
          this.alertmsg = "Please enter the Event date and time as status requires Date and Time."
          this.alertflag = true
        } else if (this.editedItem.offergiven && this.editedItem.offer.length <= 0) {
          this.alertmsg = "Please attach the offer letter"
          this.alertflag = true
          this.spinner = false
        } else {
          this.editedItem.changedAt = new Date().toISOString().substr(0, 10)
          this.editedItem.changedBy = this.name
          if (this.editedItem.offeredCTC) {
            console.log("offeredCTC", this.editedItem.offeredCTC)
          } else {
            this.editedItem.offeredCTC = 0

            console.log("this.editedItem.interviewers", this.editedItem.interviewers)
            console.log("this.editedItem.interviewers.manager", this.editedItem.interviewers.manager)

            if (asssignedlist.length > 0) {
              asssignedlistname = asssignedlist[0].name
              asssignedlistemail = asssignedlist[0].email
            }
            console.log("after asssignedlist", asssignedlistname, asssignedlistemail)

            if (this.editedItem.status == "Manager Interview") {
              this.editedItem.DateTimes.manager = { date: this.editedItem.eventdate, time: this.editedItem.eventtime }
              this.editedItem.interviewers.manager = { name: asssignedlistname, email: asssignedlistemail }
              console.log(
                "inside Manager Interview",
                this.editedItem.DateTimes.manager,
                this.editedItem.interviewers.manager
              )
            } else if (this.editedItem.status == "Client1 Interview") {
              this.editedItem.DateTimes.client1 = { date: this.editedItem.eventdate, time: this.editedItem.eventtime }
              this.editedItem.interviewers.client1 = { name: asssignedlistname, email: asssignedlistemail }
            } else if (this.editedItem.status == "Client2 Interview") {
              this.editedItem.DateTimes.client2 = { date: this.editedItem.eventdate, time: this.editedItem.eventtime }
              this.editedItem.interviewers.client1 = { name: asssignedlistname, email: asssignedlistemail }
            }
            else if (this.editedItem.status == "Client3 Interview") {
              this.editedItem.DateTimes.client3 = { date: this.editedItem.eventdate, time: this.editedItem.eventtime }
              this.editedItem.interviewers.client1 = { name: asssignedlistname, email: asssignedlistemail }
            } else if (this.editedItem.status == "Joined") {
              this.editedItem.DateTimes.joining = { date: this.editedItem.eventdate, time: this.editedItem.eventtime }
            }
          }
          this.editedItem = JSON.parse(
            JSON.stringify(this.editedItem, function (k, v) {
              if (v === undefined) {
                return null
              }
              return v
            })
          )
          console.log("inside save item ", this.editedItem)
          if (
            this.editedItem.prestatus != this.editedItem.status ||
            this.editedItem.oldassingedTo != this.editedItem.assingedTo
          )
            var mailflag = true
          try {
            this.spinner = true
            await recruitmentRef.doc(this.editedItem.uid).set({
              ...this.editedItem,
             
            })
            Object.assign(this.recruitment[this.editedIndex], this.editedItem)
            console.log("after updated", this.editedItem.status, this.editedItem.prestatus)
            if (this.editedItem.status == "Offer Printed" && this.editedItem.prestatus != "Offer Printed") {
              console.log("inside selected", this.editedItem.status, this.editedItem.prestatus)
              this.roles.candidateName = this.editedItem.candidateName
              this.roles.title = this.editedItem.title
              this.roles.offeredCTC = this.editedItem.offeredCTC
              this.roles.VariablePay = this.editedItem.VariablePay
              this.roles.bpratio = this.editedItem.bpratio
              this.roles.professionalTax = this.editedItem.professionalTax
              this.roles.sigingdate = this.editedItem.sigingdate
              this.roles.profileid = this.editedItem.uid

              console.log("roles before calling offerletter", this.roles)
              this.$router.push({ name: "offerletter", params: { roles: this.roles } })
            } else {
              this.snackbar = true
              this.snackbartext = "Profile updated successfully"
              if (mailflag == true) {
                this.mailfun()
              }

              this.close()
            }
          } catch (error) {
            console.log("Error while update :", error)
            this.spinner = false
            this.alertflag = true
            ;(this.alertmsg = "Error while update the profile"), +error
          }
        } // end  of else  if((this.editedItem.test=="Needed")
      } // end of if(this.editedIndex > -1)
      else {
        if (
          (this.editedItem.status == "Manager Interview" ||
            this.editedItem.status == "Clinet1 Interview" ||
            this.editedItem.status == "Clinet2 Interview" ||
            this.editedItem.status == "Joined") &&
          (this.editedItem.eventdate == null || this.editedItem.eventtime == null)
        ) {
          console.log(
            "inside event date and time error",
            this.editedItem.eventdate,
            this.editedItem.eventtime,
            this.editedItem.status
          )
          this.alertmsg = "Please enter the Event date and time as the status require date and time."
          this.alertflag = true
          this.spinner = false
        } else if (this.editedItem.resume.length > 0 && this.editedItem.resume.length > 0) {
          this.editedItem.domain = this.domain // add this as domain name from login page.
          this.editedItem.createdAt = new Date().toISOString().substr(0, 10)
          ;(this.editedItem.createdBy = this.email), 
          this.editedItem.changedAt = new Date().toISOString().substr(0, 10)
          ;(this.editedItem.changedBy = this.email),console.log("inside new item add", this.editedItem)

          try {
            if (asssignedlist.length > 0) {
              asssignedlistname = asssignedlist[0].name
              asssignedlistemail = asssignedlist[0].email
            }

            if (this.editedItem.status == "Manager Interview") {
              this.editedItem.DateTimes.manager = { date: this.editedItem.eventdate, time: this.editedItem.eventtime }
              this.editedItem.interviewers.manager = { name: asssignedlistname, email: asssignedlistemail }
            } else if (this.editedItem.status == "Client1 Interview") {
              this.editedItem.DateTimes.client1 = { date: this.editedItem.eventdate, time: this.editedItem.eventtime }
              this.editedItem.interviewers.client1 = { name: asssignedlistname, email: asssignedlistemail }
            } else if (this.editedItem.status == "Client2 Interview") {
              this.editedItem.DateTimes.client2 = { date: this.editedItem.eventdate, time: this.editedItem.eventtime }
              this.editedItem.interviewers.client2 = { name: asssignedlistname, email: asssignedlistemail }
            } 
            else if (this.editedItem.status == "Client3 Interview") {
              this.editedItem.DateTimes.client3 = { date: this.editedItem.eventdate, time: this.editedItem.eventtime }
              this.editedItem.interviewers.client3 = { name: asssignedlistname, email: asssignedlistemail }
            }else if (this.editedItem.status == "Joined") {
              this.editedItem.DateTimes.joining = { date: this.editedItem.eventdate, time: this.editedItem.eventtime }
            }
            this.spinner = true
            await recruitmentRef
              .add(this.editedItem)
              .then((docRef) => {
                console.log("uid for new record is ", docRef.id)
                this.editedItem.uid = docRef.id
              })
              .catch((error) => console.error("Error adding recruitmentRef: ", error))

            this.recruitment.push(this.editedItem)
            //this.showrecruitment.push(this.editedItem)
            this.snackbar = true
            this.snackbartext = "Profile added successfully"

            if (this.editedItem.assingedTo) this.mailfun()
            this.close()
          } catch (error) {
            this.alertflag = true
            ;(this.alertmsg = "Error while add the profile"), +error
            this.spinner = false
          }
        } // end of this.editedItem.images value found
        else {
          this.alertmsg = "Please attach CV"
          this.alertflag = true
          this.spinner = false
        }
      }
    },
    mailfun() {
      var cvarray
      console.log("inside mailfun", this.editedItem.offergiven, this.editedItem.offerletterurl)

      if (this.editedItem.offergiven) {
        cvarray = this.editedItem.offerletterurl
        this.mailmsg.to = this.editedItem.email
        this.mailmsg.subject = this.domain + ":Offer Letter"
        this.mailmsg.message =
          "Hi, You have been selected for the job " +
          this.editedItem.title +
          ". Please find the offer letter below and share your acceptance at the earliest"
        this.mailmsg.message =
          this.mailmsg.message + '<a href="' + cvarray + '">Click here to download the Offer Letter</a>'
        console.log(this.mailmsg)
        this.sendEmail(this.mailmsg)
      } else if (this.editedItem.status == "Client1 Interview" || this.editedItem.status == "Client2 Interview" || this.editedItem.status == "Client3 Interview") {
        console.log("authdomain is:", authdomain)
        cvarray = authdomain + "/clientprofile/" + this.editedItem.uid
        this.mailmsg.to = this.editedItem.assingedTo
        this.mailmsg.subject = "Recruitment update:" + this.domain
        this.mailmsg.message =
          "Hi, Profile is assigned to you for an Interview for : " +
          this.editedItem.title +
          ", and the profile name is  " +
          this.editedItem.candidateName +
          ". Please login to portal and update the details."
        this.mailmsg.message = this.mailmsg.message + '<a href="' + cvarray + '">Click here to access the profile</a>'
        console.log(this.mailmsg)
        this.sendEmail(this.mailmsg)
      } else {
        if (this.editedItem.resume.length > 0) {
          cvarray = this.editedItem.resume[0].fileurl

          this.mailmsg.to = this.editedItem.assingedTo
          this.mailmsg.subject = "Recruitment update"
          this.mailmsg.message =
            "Hi, New activitiy is assigned in your queue for the JOB ID " +
            this.editedItem.jobId +
            ", profile name is  " +
            this.editedItem.candidateName +
            ". Please login to portal and update the details."
          this.mailmsg.message = this.mailmsg.message + '<a href="' + cvarray + '">Click here to download the CV</a>'
          console.log(this.mailmsg)
          this.sendEmail(this.mailmsg)
        } else {
          console.log("Resume not found")
        }
      }
    },
    remove(item) {
      this.chips.splice(this.chips.indexOf(item), 1)
      this.chips = [...this.chips]
    },
  }, // end of method
}
</script>
<style scoped>
.tiptap-vuetify-editor .ProseMirror {
  min-height: 1000px;
}
</style>
