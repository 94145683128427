<template>
  <div>
    <h4>{{ lableString }}-{{ slidervalue }}</h4>
    <v-card>
      <v-card-text>
        <v-slider
          v-model="slidervalue"
          :showstep="step"
          :min="minvalue"
          :max="maxvalue"
          ticks
          thumb-label
          hide-details
          @input="assignslidervalues"
        ></v-slider>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Slider",

  props: {
    lableString: {
      type: String,
    },
    datavalue: {
      type: Number,
    },
    step: {
      type: Number,
    },
    minvalue: {
      type: Number,
      default: 0,
    },
    maxvalue: {
      type: Number,
    },
  },
  data() {
    return {
      slidervalue: this.datavalue,
    } // end of retrun
  }, //end of data
  computed: {
    getslidervalue() {
      return this.slidervalue
    },
  },
  created() {
    this.slidervalue = this.datavalue
    console.log("slider ", this.datavalue, this.slidervalue)
  },

  methods: {
    assignslidervalues() {
      this.$emit("assignslidervalues", this.slidervalue)
    },
  },
}
</script>
